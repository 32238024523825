.cssload-container {
  width: 100%;
  height: 20px;
  text-align: center;
}

.cssload-speeding-wheel {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border: 2px solid rgb(255,255,255);
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 1250ms infinite linear;
    -o-animation: cssload-spin 1250ms infinite linear;
    -ms-animation: cssload-spin 1250ms infinite linear;
    -webkit-animation: cssload-spin 1250ms infinite linear;
    -moz-animation: cssload-spin 1250ms infinite linear;
}



@keyframes cssload-spin {
  100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
  100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
  100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
  100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
  100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
